import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-84cbe30c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "el-dropdown-link"
};
import { ref } from 'vue';
import { useStore } from 'vuex';
import { ElMessage } from 'element-plus';
import { useRouter } from 'vue-router';
export default {
  __name: 'avatar',
  setup(__props) {
    const store = useStore();
    const router = useRouter();
    const squareUrl = ref('https://img0.baidu.com/it/u=1056811702,4111096278&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500');
    const logout = async () => {
      await store.dispatch('admin/logout');
      ElMessage.success('安全退出！');
      router.push('/login');
    };
    return (_ctx, _cache) => {
      const _component_el_avatar = _resolveComponent("el-avatar");
      const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
      const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
      const _component_el_dropdown = _resolveComponent("el-dropdown");
      return _openBlock(), _createBlock(_component_el_dropdown, null, {
        dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, null, {
          default: _withCtx(() => [_createVNode(_component_el_dropdown_item, {
            onClick: logout
          }, {
            default: _withCtx(() => [_createTextVNode("退出")]),
            _: 1
          })]),
          _: 1
        })]),
        default: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_createVNode(_component_el_avatar, {
          shape: "square",
          size: 40,
          src: squareUrl.value
        }, null, 8, ["src"])])]),
        _: 1
      });
    };
  }
};