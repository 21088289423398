import dayjs from 'dayjs'

const formatDate = (date) => {
  const dateObj = dayjs(date)
  return dateObj.format('YYYY-MM-DD')
}

const formatTimeLeft = (hours) => {
  // 计算整数天数
  const days = Math.floor(hours / 24)
  // 计算不满一天的小时数
  const remainingHours = hours % 24
  return `${days}天 ${remainingHours}小时`
}
export default (app) => {
  app.config.globalProperties.$filters = {
    formatDate,
    formatTimeLeft
  }
}
