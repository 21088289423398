import { get, post } from '@/utils/request'

/**
 * 获取文章列表
 * @param {*} params
 * @returns
 */
export const getArticleListApi = (params) => get({ url: '/article/list', params })

/**
 * 添加文章
 * @param {*} data
 * @returns
 */
export const addArticleApi = (data) => post({ url: '/article/add', data })

/**
 * 修改文章
 * @param {*} data
 * @returns
 */
export const updateArticleApi = (data) => post({ url: '/article/update', data })

/**
 * 删除文章
 * @param {*} data
 * @returns
 */
export const deleteArticleApi = (data) => post({ url: '/article/delete', data })
