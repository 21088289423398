import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  key: 0
};
export default {
  __name: 'MenuItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    return (_ctx, _cache) => {
      const _component_svg_icon = _resolveComponent("svg-icon");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_menu_item = _resolveComponent("el-menu-item");
      return !__props.item.hidden ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_menu_item, {
        index: '/' + __props.item.path
      }, {
        title: _withCtx(() => [_createTextVNode(_toDisplayString(__props.item.meta?.title), 1)]),
        default: _withCtx(() => [_createVNode(_component_el_icon, {
          style: {
            "width": "30px"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_svg_icon, {
            icon: __props.item.meta?.icon
          }, null, 8, ["icon"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["index"])])) : _createCommentVNode("", true);
    };
  }
};