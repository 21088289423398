import cookie from 'js-cookie'
import { adminToken } from '@/settings'

// 获取用户token
export function getToken() {
  return cookie.get(adminToken)
}

// 获取用户设置用户token
export function setToken(token) {
  return cookie.set(adminToken, token)
}

// 移除 token
export function removeToken() {
  return cookie.remove(adminToken)
}
