import { post, get } from '@/utils/request'

/**
 * 管理员登录接口
 * @param {Object} data
 * @returns
 */
export const loginApi = (data) => {
  return post({
    url: '/auth/login',
    data
  })
}

/**
 * 管理员退出登录接口
 * @returns
 */
export const logoutApi = () => {
  return get({
    url: '/auth/logout'
  })
}

/**
 * 获取管理员信息接口
 * @returns
 */
export const getInfoApi = () => {
  return get({
    url: '/auth/info'
  })
}
