import { get, post } from '@/utils/request'

/**
 * 获取订阅列表
 * @param {*} params
 * @returns
 */
export const getSubscribeListApi = (params) => get({ url: '/subscribe/list', params })

/**
 * 添加订阅
 * @param {*} data
 * @returns
 */
export const addSubscribeApi = (data) => post({ url: '/subscribe/add', data })

/**
 * 修改订阅
 * @param {*} data
 * @returns
 */
export const updateSubscribeApi = (data) => post({ url: '/subscribe/update', data })

/**
 * 删除订阅
 * @param {*} data
 * @returns
 */
export const deleteSubscribeApi = (data) => post({ url: '/subscribe/delete', data })
