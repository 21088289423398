import { createStore } from 'vuex'
import app from './modules/app'
import admin from './modules/admin'
import getters from './getters'
export default createStore({
  modules: {
    app,
    admin
  },
  getters
})
