import { get, post } from '@/utils/request'

/**
 * 获取充值列表
 * @param {*} params
 * @returns
 */
export const getRechargeListApi = (params) => get({ url: '/recharge/list', params })

/**
 * 添加充值
 * @param {*} data
 * @returns
 */
export const addRechargeApi = (data) => post({ url: '/recharge/add', data })

/**
 * 修改充值
 * @param {*} data
 * @returns
 */
export const updateRechargeApi = (data) => post({ url: '/recharge/update', data })

/**
 * 删除充值
 * @param {*} data
 * @returns
 */
export const deleteRechargeApi = (data) => post({ url: '/recharge/delete', data })
