import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue";
import menuVariables from '@/styles/variables.module.scss';
import { computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { Share } from '@element-plus/icons-vue';
import MenuItem from './MenuItem';
export default {
  __name: 'index',
  setup(__props) {
    const routes = computed(() => {
      const parentRoute = useRouter().options.routes.filter(item => item.path === '/')[0];
      return parentRoute.children;
    });
    const defaultActive = computed(() => useRoute().path);
    return (_ctx, _cache) => {
      const _component_el_menu = _resolveComponent("el-menu");
      return _openBlock(), _createBlock(_component_el_menu, {
        "default-active": defaultActive.value,
        "active-text-color": _unref(menuVariables).menuActiveText,
        "background-color": _unref(menuVariables).menuBg,
        class: "el-menu-vertical-demo",
        "text-color": _unref(menuVariables).menuText,
        router: "",
        "unique-opened": "",
        collapse: !_ctx.$store.getters.siderType
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(routes.value, route => {
          return _openBlock(), _createBlock(_unref(MenuItem), {
            key: route.path,
            item: route
          }, null, 8, ["item"]);
        }), 128))]),
        _: 1
      }, 8, ["default-active", "active-text-color", "background-color", "text-color", "collapse"]);
    };
  }
};