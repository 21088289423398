import router from '@/router'
import store from '@/store'
import nProgress from 'nprogress'
import { routerWhiteList, appTitlePrefix, successCode } from '@/settings'
import { getToken, removeToken } from '@/utils/auth'

nProgress.configure({ showSpinner: false })

router.beforeEach(async (to, from, next) => {
  nProgress.start()
  const token = getToken()

  if (token) {
    // 如果存在 token
    const adminInfo = store.state.admin
    if (to.path === '/login') {
      // 如果当前路径是登录页，强制跳转到首页
      return next('/')
    } else if (!adminInfo.username) {
      // 如果 vuex 中没有用户数据，通过已有 token 更新数据
      const result = await store.dispatch('admin/getInfo')
      if (result.code === successCode) {
        next()
      } else {
        // 获取用户数据失败，移除 token 并跳转至登录页
        removeToken()
        return next(`/login?redirect=${to.path}`)
      }
    } else {
      // 用户数据已存在，继续路由
      next()
    }
  } else {
    // 如果没有 token
    if (routerWhiteList.includes(to.path)) {
      // 如果当前路径在白名单中，允许访问
      next()
    } else {
      // 不在白名单中，跳转至登录页
      next(`/login?redirect=${to.path}`)
    }
  }
})

router.afterEach((to) => {
  nProgress.done()
  // 设置页面标题
  document.title = to.meta && to.meta.title ? to.meta.title : appTitlePrefix
})
