import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-38fd029e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "navbar"
};
const _hoisted_2 = {
  class: "navbar-right"
};
import Hamburger from './components/hamburger';
import Breadcrumb from './components/breadcrumb';
import Avatar from './components/avatar';
export default {
  __name: 'index',
  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(Hamburger)), _createVNode(_unref(Breadcrumb)), _createElementVNode("div", _hoisted_2, [_createVNode(_unref(Avatar), {
        class: "navbar-item"
      })])]);
    };
  }
};