import { get, post } from '@/utils/request'

/**
 * 查询密码列表接口
 * @param {*} params
 * @returns
 */
export const getPasswordListApi = (params) => get({ url: '/password/list', params })

/**
 * 添加密码接口
 * @param {*} data
 * @returns
 */
export const addPasswordApi = (data) => post({ url: '/password/add', data })

/**
 * 激活密码接口
 * @param {*} data
 * @returns
 */
export const activePasswordApi = (data) => post({ url: '/password/active', data })
