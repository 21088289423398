import { createRouter, createWebHistory } from 'vue-router'
import Layout from '@/layout'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { title: '主页' },
    component: Layout,
    redirect: 'user',
    children: [
      {
        path: 'user',
        name: 'User',
        meta: { icon: 'eye-open', title: '用户管理' },
        component: () => import('@/views/user')
      },
      {
        path: 'password',
        name: 'Password',
        meta: { icon: 'eye-open', title: '密码管理' },
        component: () => import('@/views/password')
      },
      {
        path: 'subscribe',
        name: 'Subscribe',
        meta: { icon: 'eye-open', title: '订阅管理' },
        component: () => import('@/views/subscribe')
      },
      {
        path: 'article',
        name: 'Article',
        meta: { icon: 'eye-open', title: '文章管理' },
        component: () => import('@/views/article')
      },
      {
        path: 'recharge',
        name: 'Recharge',
        meta: { icon: 'eye-open', title: '充值管理' },
        component: () => import('@/views/recharge')
      }
      // ,
      // {
      //   path: 'help',
      //   name: 'Help',
      //   meta: { icon: 'eye-open', title: '帮助中心' },
      //   component: () => import('@/views/help')
      // }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
