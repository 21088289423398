import { get, post } from '@/utils/request'

/**
 * 获取用户列表
 * @param {*} params
 * @returns
 */
export const getUserListApi = (params) => get({ url: '/user/list', params })

/**
 * 添加用户
 * @param {*} data
 * @returns
 */
export const addUserApi = (data) => post({ url: '/user/add', data })

/**
 * 修改用户
 * @param {*} data
 * @returns
 */
export const updateUserApi = (data) => post({ url: '/user/update', data })

/**
 * 删除用户
 * @param {*} data
 * @returns
 */
export const deleteUserApi = (data) => post({ url: '/user/delete', data })
